.loan_application_main_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loan-application-container {
  display: flex;
  flex-direction: row;
  /* margin-left: 17px; */
}


.fixed-width-button {
  width: 70px;
  


 
}
.div_first{
  width: 10%;

}
.div_sec{
  width: 15%;
 



 

padding-left: 30px;
}
.div_second{
  width: 18%;
  /* margin-left: 20px; */
  padding-left: 10px;
 



}



.div_third{
  width: 21%;
  padding-left: 24px;
  /* margin-left: 10px; */


  
}
.left_content {
  display: flex;
}
.div_fourth{
  width: 24%;

  padding-left: 3px;

}
.div_five{
  width: 14%;




}
.div_six{
  width: 10%;



}
.applcation_div_container{

  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}

.right_content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.go_button_div {
  height: 36px;
}
.stage_button {
  padding: 13px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 13px;
}

.select_stage {
  width: 258px;
  height: 36px;
}

.container {
  display: flex;
  /* padding: 20px 25px 0px 20px; */
  /* white-space: nowrap; */
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-x: auto;
  width: 99%;
  max-width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

@media screen and (max-width: 1060px) {
  .container {
    width: 100%;
    max-width: 100%;
    padding: 20px 20px 0px 20px;
  }

  .section {
    flex: 0 0 auto;
    cursor: pointer;
  }
}

@media screen and (max-width: 860px) {
  .container {
    width: 100%;
    /* Keep width full */
    max-width: 100%;
    padding: 20px 20px 0px 20px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 100%;
    max-width: 100%;
    padding: 20px 20px 0px 20px;
  }
}

/* .disbursed-container {
  color: #389e0d;
  font-size: 18px;
  display: flex;
  background: #f6ffed;
  border-radius: 16px;
  align-items: center;
  font-family: "Roboto";
  font-weight: "500";
  word-wrap: "break-word";
} */

.loangenerate_button {
  background-color: hsla(204, 57%, 36%, 1);
  height: 35px;
}

.loan-details p {
  margin: 0;
  color: #27658f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.28px;
}

.parent-stage-font-view {
  color: #27658f;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
}

.loan-details h1 {
  color: #101828;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.48px;
  margin-top: 5px;
}

.loan-type {
  text-align: center;
  font-family: Poppins;
  color: #389e0d;
  font-size: 14px;
  font-weight: 500;
  margin: 23px 0 10px 15px;
  padding: 3px 10px 2px 10px;
  background: #f6ffed;
  border-radius: 16px;
}

.loan_application_progress {
  margin-top: 30px;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #e4e7ec;
}

@media screen and (max-width: 840px) {
  .loan_application_progress_content {
    flex-direction: column;
    align-items: flex-start;
  }

  .right_content {
    margin-top: 10px;
  }
}

.loan_application_progress_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 7px;
}

@media only screen and (max-width: 600px) {
  .loan_application_progress_content {
    flex-direction: column;
    align-items: flex-start;
  }

  .tooltip-text {
    margin-bottom: 8px;
    font-size: 14px;
  }

  .select_stage {
    width: 100%;
  }

  .left_content {
    flex-direction: column;
    align-items: flex-start;
  }

  .right_content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .custom-tooltip {
    font-size: 12px;
  }
}

.tooltip-text {
  color: #161616;
  margin: 13px 17px 13px 0px;
  font-family: "Roboto";
  word-wrap: break-word;
  font-size: 24px !important;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  margin-left: 17px;
}

.custom-tooltip .ant-tooltip-inner {
  width: 200%;
  color: #101828;
  font-family: "Roboto";
  background-color: #ffffff !important;
}

.divider {
  margin: 0;
}

.section {
  text-align: center;
  padding: 10px 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  /* border: 1px solid red; */
  word-wrap: "break-word";
  cursor: pointer;
}

.section_initiated {
  color: #00c7c4;
  background-color: #e3fffc;
}

.section_completed {
  color: #28a745;
  background-color: #f3ffec;
}

.section_reinitiated {
  color: #c524c8;
  background-color: #f6e0f5;
}

.section_default {
  color: #6c757d;
  background-color: #f0f0f0;
}

.success_section {
  color: #389e0d;
  background-color: #f6ffed;
}

.section_hold {
  color: #dc3545;
  background-color: hsla(53, 97%, 88%, 1);
}

.section_query {
  color: #ffbf00;
  background-color: hsla(53, 97%, 88%, 1);
}

.success_financial_section {
  color: hsla(33, 92%, 33%, 1);
  background-color: hsla(53, 97%, 88%, 1);
}

.danger_section {
  color: #b32318;
  background-color: hsla(53, 97%, 88%, 1);
}

.legal_section {
  color: hsla(188, 48%, 48%, 1);
  background-color: #f0f9ff;
}

.technical_section {
  color: #389e0d;
  background-color: #f6ffed;
}

.section:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-left: 1px solid #ccc;
}

.section:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-right: 1px solid #ccc;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.custom-card {
  width: 278px;
  height: 367px;
}

.container-header {
  display: flex;
}

.container_heading_content {
  margin-left: 20px;
}

.custom-card .ant-card-body {
  padding-right: 0;
  padding-left: 20px;
}

.loan_label {
  color: #00000080;
  /* padding: 12px; */
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding-top: 2px;
}

.card {
  width: 30%;
  margin-bottom: 20px;
}

.laon_data {
  color: black;
  padding-left: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.card-info {
  color: #27658f;
  font-family: Roboto;
  width: 145px;
  height: 36px;
  background: #27658f1a;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14;
}

.card-content {
  margin-top: 25px;
}

.card-content1 {
  margin-top: 9px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: #667085;
  font-family: Roboto;
  line-height: 20px;
}

.data {
  color: #101828;
  font-size: 16px;
  font-family: Roboto;
  line-height: 44px;
  letter-spacing: -0.32px;
}

.card-content p {
  margin: 0px;
}

.images-container {
  display: flex;
  align-items: center;

  gap: 15px;
  padding: none;
}

.coapplicant-image {
  width: 35px;
  height: 35px;
  background-color: #e0e2df;
  border-radius: 16px;
}

.color-code {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 3px;
  margin-top: 1px;
}

.tooltip-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 5px;
}

.color-code span {
  display: block;
  text-align: center;
  margin-top: 8px;
}

.Basic_details_container {
  display: flex;
  justify-content: space-between;

  flex-wrap: wrap;
  /* Allows the cards to wrap to the next line */

  /* border: 1px solid #e4e7ec;  */
}

.loan_insurance_details_container {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid #E4E7EC; */
  /* margin: 0 16px 8px; */
  border-top: none;
}

.Basic_card {
  margin: 10px;
  flex: 1 1 20%;
  /* Flex-grow: 1, Flex-shrink: 1, Basis: 30% */
  margin-bottom: 20px;
  box-sizing: border-box;
  /* Ensure padding/margin is included in width */
  min-width: 220px;
  /* Set a minimum width to prevent cards from getting too small */
}

.basic_card-content {
  gap: 8px;
  height: auto;
  margin-top: 13px;
  /* border: 1px solid red; */
  padding-top: 3px;
}

.main_loan_basic_container {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  border-radius: 8px;
  margin-top: 20px;
  border: 1px solid #e4e7ec;

  /* margin: 0 15px 8px; */

  /* border: 1px solid #E4E7EC; */
  /* width: 97.3%; */
}

.main_page_loan {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e4e7ec;
  flex-wrap: wrap;
}

.user_image_loan {
  height: 41px;
  font-size: 24px;
}

.card_location {
  margin-left: 15px;
  margin-right: 200px;
}

.card_loan {
  margin-left: 15px;
}

.laon_main_page {
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-left: 1px solid #e4e7ec;
}

.laon_main_page_first {
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .laon_main_page_first {
    margin-left: 30px; /* Remove left margin for smaller screens */
    margin-top: 15px; /* Adjust top margin */
    margin-bottom: 15px; /* Adjust bottom margin */
  }
}



/* Case Health Summary css fixed */
.home_application_summary_heading {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.02em;
  text-align: left;
  color: rgba(16, 24, 40, 1);
}

.scroll-button {
  position: fixed;
  bottom: 48px;
  right: 48px;
  z-index: 1000;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
}
.float_chat_button {
  margin-right: 120px;
}

.float_button {
  margin-right: 70px;
}

.float_Button_two {
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  margin-right: 1px !important;
  margin-bottom: 13px !important;
  font-size: 24px !important;
  background-color: #ffffff !important;
  transition: background-color 0.2s !important;
  border: 1px solid #ffffff !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3) !important;
}

.qustion_circle_outline {
  font-size: 17px;
  color: #667085;
  cursor: pointer;
}

/* stage drawer icon */
.stage_icons {
  height: 30px;
  width: 30px;
  margin-left: 5px;
  cursor: pointer;
}

.stage_status_link {
  text-decoration: underline;
}

.sendback_modal {
  top: 10%;
}

.sendback_modal_divider {
  margin: 15px 0px;
}

.loan_status_tooltip {
  margin-bottom: 8px;
}

.loan_tooltip_color_status_block {
  display: flex;
}

.loan_tooltip_complete_status {
  background-color: #28a745;
}

.loan_tooltip_initiated_status {
  background-color: #00c7c4;
}

.loan_tooltip_cancelled_status {
  background-color: #e31557;
}

.loan_tooltip_pending_status {
  background-color: #6c757d;
}

.loan_tooltip_query_status {
  background-color: #ffbf00;
}

.loan_tooltip_void_status {
  background-color: #dc3545;
}

.history-row {
  background-color: hwb(0 83% 17% / 0.330);
  height: 50px !important; 
}

#chat_drawer.ant-drawer-body {
  padding: 0 !important;
}



