.chatbox_main_container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensures chatbox takes full height */
  overflow: hidden; /* Prevents chat window from moving */
}

.chatbox_header {
  height: 68px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(229, 229, 234, 1);
}

.chatbox_footer {
  display: flex;
  align-items: center;
  padding: 5px;
  background: white;
  width: 100%;
  border-top: 1px solid #ccc;
}

.attach_btn, .send_btn {
  padding: 0 10px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attach_btn input {
  display: none; /* Hides file input */
}
.chat_input {
  width: 100%;
  min-height: 40px;
  max-height: 120px;
  overflow-y: auto; /* Ensures only one vertical scroll */

  outline: none; /* Removes focus outline */
  resize: none;
}



.chat_input textarea {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  resize: none;
  background: transparent;
  
}
.chatbox_message_window {
  height: calc(100vh - 116px);
  overflow-y: auto;
}

.chatbox_messages {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.chat_bubble {
  display: flex;
  align-items: flex-end;
  max-width: 80%;
}

.chat_notification_bubble {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 70%;
  align-self: center;
  justify-content: center;
}

.chat_bubble.sent {
  align-self: flex-end;
  flex-direction: row-reverse;
}

.chat_bubble.received {
  align-self: flex-start;
}

.chat_avatar {
  margin: 0 8px;
}

.chat_content {
  padding: 10px;
  border-radius: 16px;
  font-size: 14px;
  max-width: 70%;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Sent Messages */
.sent .chat_content {
  background: rgba(0, 122, 255, 1);
  color: #fff;
  border-bottom-right-radius: 0;
}

/* Received Messages */
.received .chat_content {
  background: #f0f0f0;
  color: #000;
  border-bottom-left-radius: 0;
}

/* Message Text */
.chat_text {
  margin: 0;
  padding-right: 30px;
}

/* Timestamp */
.chat_time_sent {
  font-size: 12px;
  color: rgba(102, 102, 104, 1);
  align-self: flex-end;
  margin: 10px;
  flex-shrink: 0;
}

.chat_time_received {
  font-size: 12px;
  color: rgba(102, 102, 104, 1);
  align-self: flex-end;
  margin: 10px;
  flex-shrink: 0;
}

.chat_input_wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  /* padding: 8px; */
  background: white;
  position: relative;

}

.image_preview_container {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 8px;
}

.image_preview {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin: 5px; /* Add spacing around the image */
  padding: 3px; /* Optional padding inside the container */
  object-fit: cover;
}


.image_delete_icon {
  position: absolute;
  top: -5px;
  right: -5px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
  font-size: 14px;
}
.image_delete_icon:hover {
  background: rgba(0, 0, 0, 0.8);
}

.tagged_user_sent {
  /* background-color: #b5e2ff; */
  color: #fff;
  font-weight: bold;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
}

.tagged_user_received {
  /* background-color: #b5e2ff; */
  color: #000;
  font-weight: bold;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
}

.tagged_user_notification {
  color: #000;
  font-weight: bold;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
}


.chat_image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 6px;
}

.mention_suggestions {
  position: absolute;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
  width: 100%;
  bottom: 47px;
  left: 0px;
}

.mention_suggestion_item {
  padding: 8px;
  cursor: pointer;
}

.mention_suggestion_item:hover {
  background-color: #f0f0f0;
}
/* 
.tagged_user {
  color: #cecece;
  font-weight: bold;
} */

.mention_input strong {
  background-color: #C4C7C5;
  font-weight: bold;
  padding: 4px 1px ;
  border-radius: 5px;
}

.chat_notification_content {
  background-color: lightgoldenrodyellow;
  padding: 10px;
  border-radius: 16px;
  color: darkgoldenrod;
  text-align: center;
}

.chat_notification_text {
  text-align: justify;
  margin-bottom: 4px;
}

.chat_avatar {
  margin: 0 8px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  background-color: "#1677ff";
}

.notification_time {
  align-self: center;
  font-size: 12px;
  color: rgba(102, 102, 104, 1);
  margin: 10px;
  flex-shrink: 0;
}
