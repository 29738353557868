@media (max-width: 768px) {
    .calendar-container {
      padding: 0 5px;
    }
  }
  


.react-calendar {
  border: none;
  font-family: 'Roboto', sans-serif;
  padding: 10px;
  border: none !important;
  background-color: white;
  line-height:1.5rem !important;
}

.react-calendar__month-view__days__day {
  padding: 10px;
  text-align: center;
  font-size: 14px;
  border-radius: 50%;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.react-calendar__month-view__days__day--today {
  background-color: #1890ff; 
  color: white;
}

.react-calendar__month-view__days__day--selected {
  background-color: #1890ff !important; 
  color: white !important;
}

.react-calendar__tile {
  padding: 10px;
  background: none;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.react-calendar__tile:enabled:hover {
  background-color: #f0f5ff; 
}

.react-calendar__tile:enabled:focus {
  outline: none;
  background-color: #e6f7ff;
}

