.main_container {
  /* display: flex; */
  width: 100%;
  /* border: 1px solid red; */
  /* overflow-x: auto; */
}

.credit_box {
  display: flex;
}

.credit_table {
  /* width: 590px; */
  flex: 1;
  height: auto;
  left: 17px;
  gap: 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
}

.credit_table .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
  border-start-end-radius: 0px;
}

.credit_table .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
  border-start-start-radius: 0px;
}


.debit_box {
  display: flex;
}

/* .cheque_div {
  width: 350px;
} */

.balance_div {
  width: 600px;
}
.banking_bank_name{
  margin-top: 20px;
  font-size: 20px;
  font-weight: 800;
}
.debit_table {
  /* width: 590px; */
  flex: 1;
  height: auto;
  top: 154px;
  left: 745px;
  gap: 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;

}

.debit_table .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
  border-start-end-radius: 0px;
}

.debit_table .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
  border-start-start-radius: 0px;
}

.main_credit p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  /* margin-top: 30px; */
  margin-bottom: 10px;
  margin-left: 0px;
  text-decoration: underline;
}

.main_debit p {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 10px;
  text-decoration: underline;
  margin-left: 0px;
}

.cheque_div {
  width: Fixed (353px)px;
  height: 47px;
  top: 101px;
  left: 742px;
  padding: 12px 0px 0px 0px;
  gap: 4px;
  border-bottom: 1px solid rgb(238, 233, 233);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  background: #F1F1F1;
}


.ant-table-thead th {
  background-color: #F1F1F1;
  /* Your desired background color */
  font-weight: bold;
  /* Optional: Set the font weight */
  color: #27658F;
  /* Optional: Set the text color */
}

.credit-label {
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
}

  .total-row {
    color: #FFA726; 
    font-weight: bold;
  }
  
  
  

  @media screen and (max-width: 1024px) {
    .credit_box, .debit_box {
      flex-direction: column;
      align-items: center;
    }
  
    .credit_table, .debit_table {
      width: 90%;
    }
  
    .cheque_div {
      width: 90%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .credit_table, .debit_table {
      width: 100%;
      overflow-x: auto;
    }
  
    .cheque_div {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 480px) {
    .main_credit p, .main_debit p {
      font-size: 14px;
      text-align: center;
    }
  
    .credit_table, .debit_table {
      width: 100%;
    }
  }
  