.overall_main_container {
  max-width: 100%;
  border: 1px solid #ddd;
  /* overflow-x: scroll; */
  /* margin: 15px; */
}
.overall{
  margin: 10px;
}
.overall_status {
  /* width: 1159px; */
  border: 1px solid #ddd;
  border-radius: 6px;
  margin: 10px;
}
.dedupe_overall_status {
  margin: 10px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  /* color: #389E0D; */
}

.positive_status {
  color: #52c41a;
}

.negative_status {
  color: red;
}

.tab_form_sub_edit_dedupe {
  display: flex;
  justify-content: flex-end; 
  margin: 10px 10px 0px 0px;
  /* align-items: center;  */
  cursor: pointer;
}

.tab_form_sub_edit_dedupe img {
  width: 25px; 
  height: auto; 
}