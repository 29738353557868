.case_health_summary_main_container {
    margin: 0px 10px 10px 10px;
    height: auto;
}

.case_Health_Summary_decision_summary {
    height: 161px;
    top: 144px;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #e2e1e1;
    background: #FFFFFF;

}

.decision_summary {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.summary_heading {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.44px;
    margin-top: 1rem;
}

.summary {
    width: 300px;
    height: 110px;
    margin-left: 15px;
}

.credit_heading {
    margin-top: 20px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
}

.colourbar {
    display: flex;
    margin-right: 30px;
}

.red_color {
    width: 42px;
    height: 28px;
    background-color: red;
}

.orange_color {
    width: 39px;
    height: 28px;
    background: #FF7316;
}

.yellow_color {
    width: 39px;
    height: 28px;
    background: #FFB400;
}

.green_color {
    width: 96px;
    height: 28px;
    background: #96C37B;
}

.light_green_color {
    width: 39px;
    height: 28px;
    background: #6BBB48;
}

.text_confident {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: center;
    margin-left: 1px;
    margin-top: 6px;
}

.approve_decision {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    margin-left: 5px;
    margin-top: 1rem;
    margin-left: 1px;
}

.button_refer {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 16.41px;
    color: #F15251;
    margin-top: 10px;
}

.logo {
    display: flex;
    justify-content: space-between;
}

.image_avtar {
    height: 32px;
    width: 32px;
    margin-top: 5px;
}

.name {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
    color: #000000CC;
    margin-right: 140px;
}

.date {
    margin-top: 0.5rem;
    margin-left: 1px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 14.06px;
    color: rgba(0, 0, 0, 0.5);
}

.decision_mekar {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.23px;
}

.approved_image {
    width: 100px;
    height: 100px;
    margin-top: 1.5rem;
    margin-right: 3rem;
}

.customer_container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    border-radius: 8px 8px 8px 8px;
    width: 100%;
}

.customer_info_div {
    width: 49.2%;
    border: 1px solid #e2e1e1;
    border-radius: 8px 8px 8px 8px;
}

.map_div {
    width: 700;
    border: 1px solid green;
    border: 1px solid #e2e1e1;
    border-radius: 8px 8px 8px 8px;
    margin-top: 0.5rem;
}

.customer_heading {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.44px;
    text-align: left;
    color: #101828;
    margin-top: 1rem;
    margin-left: 1rem;
}

.customer_details_content {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    background: #fff;
    color: rgba(0, 0, 0, 0.7);
}

.customer_box {
    width: 30px;
    height: 24px;
    margin-left: 1rem;
    margin-top: 10px;
    background: #D9D9D9;
    line-height: 20px;
}

.details_info {
    margin-top: 1rem;
    margin-left: 10px;
    height: 31px;
    margin-top: 13px;
    width: 100%;
}

.customer_details {
    display: flex;
    justify-content: space-between;
}

.number {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 500;
    line-height: 25.78px;
    margin-right: 3rem;
    margin-top: 12px;
}

.applicants-number {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 500;
    line-height: 25.78px;
    margin-right: 3rem;
    margin-top: 12px;
    color: #f93c65 !important;
}

.bureau-number {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 500;
    line-height: 25.78px;
    margin-right: 3rem;
    margin-top: 12px;
    color: #52c41a !important;
}

.location {
    width: 100%;
    height: 130px;
}

.percentage {
    margin-left: 3%;
    margin-bottom: 15px;
}

.location_details {
    display: flex;
    justify-content: space-between;
}

.sale_value {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-left: 1rem;
    color: #00000080;
}

.fsv_sale_value {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #00000080;
    margin-left: 1.5rem;
    margin-top: 2rem;
}

.loan_sale_value {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #00000080;
    margin-left: 1.2rem;
    margin-top: 2rem;
    margin-right: 3rem;
}

.ltv_sale_value {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #00000080;
    margin-left: 13.5rem;
    margin-top: 2rem;
}


.micr-label {
    color: #00000080;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 12px;
}

.arrow {
    margin-top: 17px;
    margin-left: 10px;
}

.amount {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-left: 1.5rem;
}

.loan_amount {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-left: 1rem;
    margin-right: 5rem,
}

.chart {
    width: '390px'
}

.sale_value {
    margin-top: 0.5rem;
    text-align: center;
    margin-left: 0rem;
}

.num {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #52C41A;
    margin-left: 30px;
}

.account_main_container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.pichart {
    width: 49.2%;
    height: 550px;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #e2e1e1;
}

.piechart_ammount {
    font-family: Inter;
    font-size: 30px;
    font-weight: 500;
    line-height: 36.31px;
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.piechart_details {
    margin-top: 0.3rem;
    margin-left: 1rem;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    color: rgba(0, 0, 0, 0.5);
}

.account_section_divider {
    border-top: 1px solid #e2e1e1;
}

.bank_account_details {
    width: 49.2%;
    min-height: 300px;
    max-height: 550px;
    /* overflow-y: auto; */
    border-radius: 8px;
    border: 1px solid #e2e1e1;
}

.bank_heading {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.44px;
    margin-left: 1.5rem;
    margin-top: 1.5rem;
}

.bank_customer_details {
    display: flex;
    justify-content: space-between;
}

.bank_image {
    width: 32px;
    height: 32px;
    margin-top: 1rem;
    margin-left: 1rem;
}

.customer_name {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 0.3rem;
    margin-left: 0.5rem;
}

.name_saving {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    margin-left: 0.5rem;
    margin-bottom: 5rem;
    color: rgba(0, 0, 0, 0.45);
}

.bank_main_container {
    margin: 1rem;
    border-radius: 4px;
    /* border-bottom: 1px solid #e2e1e1; */
    min-height: 250px;
    max-height: 470px;
    overflow-y: auto;
}

.bank_details_details {
    display: flex;
    justify-content: space-between;
    border-radius: 4px 0 4px 4px;
    background: #FAFAFA;
    border: 1px solid #E4E7EC;
    /* border-bottom: none; */
    height: 65px;
    width: 100%;
}

.bank_details_details_content {
    border-radius: 4px 0 4px 4px;
    border-top: none;
    border: 1px solid #E4E7EC;
}

.account_div_one {
    display: flex;
    justify-content: space-between;
    margin-right: 1rem;
}

.verify_img_case_health {
    /* border: 1px solid red; */
    font-family: Roboto;
    /* font-size: 5px; */
    /* font-weight: 400; */
    line-height: 22px;
    margin-right: 20px;
    margin-top: 1.1rem;
    /* margin:0px; */
    height:23px;
    width:23px;
    
    color: green;
}

.table_chart {
    border: 1px solid #e2e1e1;
    height: 600px;
    border-radius: 8px 8px 8px 8px;
    margin-top: 1rem;
    height: auto;
}

.verification_text {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.44px;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
}

.table_heading_row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #79A6F2
}

.main_table {
    border: 1px solid #e2e1e1;
    border-radius: 4px 4px 4px 4px;
    margin: 2rem;
    height: auto;
}

.active_loan {
    height: auto;
    border-radius: 8px 8px 8px 8px;
    margin-top: 1rem;
    border: 1px solid #e2e1e1;
}

.foir {
    margin-top: 1rem;
    margin-left: 1rem;
}

.foir_table_content {
    margin-top: 0.5rem;
    margin-left: 0.7rem;
}

.foir_text {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.44px;
    text-align: left;
}

.main_active_loan {
    display: flex;
    justify-content: space-between;
}

.active_loan_first {
    width: 630px;
}

.auto_loan {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.auto {
    width: 150px;
    margin-top: 1rem;
    margin-left: 1rem;

}

.progress {
    width: 300px;
    height: 20px;
    margin-top: 1.2rem;
    margin-left: 1rem;
}

.progressbar_content {
    margin: 0px 5px 0px 5px;
}

.sanction {
    width: 300px;
    margin-top: 1rem;
    margin-left: 1rem;

}

.active_loan_two {
    width: 700px;
    height: auto;
    margin-right: 1rem;
    border-radius: 8px;
    border: 1px solid #e2e1e1;
    overflow: hidden;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.active_loan_two .ant-table-wrapper {
    height: 100%;
}

.active_loan_two .ant-table {
    height: 100%;
}

.active_loan_two .ant-table-body {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

/* *********************** */

.bank_information {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%
}

.account_card {
    width: 33.3%;
}

.bank_label {
    color: #00000080;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 12px;
}

.data {
    color: #000000b3;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    padding: 0;
}



/* *********************************** */

/* tables css */

.case_health_main_tables_container {
    border: 1px solid #e2e1e1;
    border-radius: 8px 8px 8px 8px;
    margin-top: 1rem;
    height: auto;
}

.tables_headings {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

.table_sub_heading {
    margin-top: 0.5rem;
    margin-left: 2px;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
}


.table_sub_heading_text {
    color: rgba(0, 0, 0, 0.7);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    background: #fff;
    margin-top: 0.5rem;
    margin-left: 10px;
}

.table_under_field {
    margin-left: 10px
}

.customer_table {
    border: 1px solid #e2e1e1;
    border-radius: 4px 4px 4px 4px;
    margin: 1rem;
    height: auto;
    /* overflow-y: auto; */
}

.case_health_summary_tables_block_container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.property_value_container {
    width: 49.2%;
    min-height: 100px;
    max-height: 200px;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #e2e1e1;
}

.property_value_contenet {
    display: flex;
    margin-left: 16px;
}

.applicant_bacnkground_container {
    width: 49.2%;
    /* min-height: 200px;
    max-height: 300px; */
    height: auto;
    border-radius: 8px;
    border: 1px solid #e2e1e1;

}

.applicant_background_contenet {
    margin: 0px 16px 16px 16px;
    /* overflow-y: auto !important; */
    /* min-height: 80px;
    max-height: 150px; */
    /* border:1px solid red; */
    margin: 1rem;
}

.case_health_summary_lables {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 23.44px;
    text-align: left;
}

.case_health_summary_salaried {
    margin: 5px 0px 0px 11px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 23.44px;
}

.custom-category-header {
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
}

.business_contenet {
    border: 1px solid #e2e1e1;
    border-radius: 8px 8px 8px 8px;
    margin: 10px 16px 16px 16px;
}


.case_health_tables_heading {
    margin-top: 10px;
    margin-left: 10px;
}


.property_value_container {
    width: 49.2%;
    min-height: 100px;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 8px;
    border: 1px solid #e2e1e1;
}


.tables_text {
    color: rgba(0, 0, 0, 0.7);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    background: #fff
}

.tables_container_headings {
    border-bottom: 1px solid rgba(239, 224, 255, 0.4);
    padding: 5px;
    background: rgba(239, 224, 255, 0.4);
    height: 45.93px;
}

.tables_container_headings_content {
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    text-align: left;
    margin: 8px;
    color: rgba(0, 0, 0, 0.6);

}

.cibil_container {
    border: 1px solid #e2e1e1;
    border-radius: 8px 8px 8px 8px;
    margin: 10px 16px 16px 16px;
}

/* ************************************** */

.eligibility_main_container{
    width: 49.2%;
    /* min-height: 200px;
    max-height: 300px; */
    border-radius: 8px;
    border: 1px solid #e2e1e1;

}

.eligibility_calculation {
    border: 1px solid #e2e1e1;
    border-bottom: none;
    border-radius: 4px 4px 4px 4px;
    margin: 1rem;
}

.calculation_tables_sheet_table {
    height: auto;
}

.calculation_sheet {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 10px;
    background: rgba(249, 250, 251, 1);
    height: 45.93px;
    color: rgba(0, 0, 0, 0.7);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
}


.eligibility_table .ant-table-tbody > tr > td:first-child {
    background: rgba(250, 250, 250, 1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06)
  }
  

  .eligibility_table .custom-column {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
}

.proposal_details_table .ant-table-tbody > tr > td:first-child {
    background: rgba(250, 250, 250, 1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06)
  }


.sanction_data {
    white-space: normal; /* Allow text to wrap to the next line */
    word-wrap: break-word; /* Break words if they are too long */
}

.ant-table-cell {
    text-align: left; /* Align text to the left */
    word-wrap: break-word;
    white-space: normal;
}


.strength_weekness_container{
    margin: 1rem;
    border: 1px solid #e2e1e1;
    border-radius: 8px 8px 8px 8px;
}

.strength_weekness{

    border-bottom: 1px solid rgba(239, 224, 255, 0.4);
        padding: 5px;
        background: rgba(239, 224, 255, 0.4);
        height: 45.93px;
}
.property_background_container {
    width: 49.2%;
    height: auto;
    border-radius: 8px;
    border: 1px solid #e2e1e1;
}

.property_background_contenet{
    margin: 1rem;
    height: auto;
}

.property_area_block{
    display: flex;
}

.property_area_text {
    color: rgba(0, 0, 0, 0.7);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    background: #fff;
    margin-left: 10px;
    
}

.verify-icon {
    width: 25px; /* Increased size */
    height: 25px;
  }
  
  .red-cross {
    color: red !important;
    font-size: 13px; /* Reduced size */
    font-weight: bold;
  }
  