.card_container {
    display: flex;
    max-width: 100%;
    height: auto;
    border: 0.5px solid rgb(153, 153, 153);
    border-radius: 8px;
    gap: 8px;
    margin-bottom: 8px;
}

.right_content_div {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.left_avatar_div {
    margin: 8px 0 8px 8px;
}

.notification_title {
    font-size: 14px;
    font-weight: 500;
    color: "#101828";
    margin-bottom: 0px;
}

.is_user_notification_title {
    font-weight: 700;
}

.notification_description {
    font-size: 13px;
    font-weight: 300;
    color: #101828;
    margin-bottom: 0px;
    margin-top: 8px;
}

.is_loan_detail_notification_description {
    font-weight: 600;
    color: #27658f !important;
    display: inline;
    cursor: pointer;
}