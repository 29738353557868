.customer_main_container {
  height: auto;
  top: 2002px;
  left: 58px;
  border-radius: 8px;
  border: 1px;
  gap: 12px;
  border: 1px solid #e4e7ec;
  margin-top: 30px;
}

.customer_main_heading {
  border-bottom: 1px solid #e4e7ec;
  display: flex;
  justify-content: space-between;
}

.customer_headding {
  height: 32px;
  margin-top: 0.8rem;
}

.customer_details_heading {
  color: hsla(0, 0%, 9%, 1);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-left: 1.5rem;
  margin-top: 1rem;
}

.customer_title {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  margin-left: 1rem;
}

.customer_icon {
  /* width: 24px; */
  height: 34px;

  padding: 3px;
  margin-top: 1rem;
  margin-right: 1rem;
}

.customer_tab_content {
  margin-bottom: 20px;
}

.customer_main_heading_border {
  border-bottom: none;
}

.customer_collapse_icon {
  cursor: pointer;
}

.customer_block_content {
  margin: 20px;
}

.customer_block_content_show {
  display: block;
}

.customer_block_content_hide {
  display: none;
}

.setting_button{
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.settings_form_button{
  display: flex;
  flex-direction: row-reverse;
  margin-top: 15%;
  /* border: 1px solid red; */
}