.audio_deatails_container {
    display: flex;
    justify-content: space-between;
    border: 1px solid #E4E7EC;

    border-top: none;

}


.audio_card {
    gap: 8px;
    margin-top: 9px;
    width: 20%;
    flex-shrink: 0;
    /* border-bottom: 1px solid var(--Conditional-divider, rgba(0, 0, 0, 0.06)); */
    margin-top: 0px;
    height: auto;
}

.audio_card-content_first {
    background: #F1F1F1;
}


.audio_card_content_second {
    height: 300px;
    border-bottom: 1px solid #EBEBEB;
    background: #FAFAFA;
    width: 20%;
   
}



.audio_para {
    margin-top: 40px;
    padding-left: 0px !important;

}

.vedio_para {
    margin-top: 130px;
    padding-left: 0px !important;

}

.audio_card-content_third {
    gap: 8px;
    height: 150px;
    border-radius: 0px 0px 0px 8px;
    background: #FAFAFA;
    color: #000000B2;
    height: 200px;

}

.audio_card_second {
    gap: 8px;
    width: 80%;
    align-items: center;
    flex-shrink: 0;
    margin-top: 0px;
  
    /* background-color: white !important; */
}

.audio_card-content_first .label {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}


.audio_card p {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

}

.audio_card_second p {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

}

.div_audio {
    border: 1px solid green;
}

.box_gallery {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;

}

.box_gallery_content {
    width: 30%;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 15px;
    display: flex;


}

.audio_gallery_content {
    width: 20%;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.video_gallery_content {
    height: 299px;
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}



.audio_box_gallery {
    width: 90px;
    height: 90px;
    flex-shrink: 0;
    border-radius: 1px;
    background: #D9D9D9;
}


.audio_card-content {

    height: auto;
    margin-top: 20px;
    margin-left: 8px;
    margin-right: 8px;
    padding-top: 15px;
    width: 238px;
}

.card_content_video_details {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-bottom: 1px solid #EBEBEB;
}

.card_content_audio_details {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.video_div {
    display: flex;
    justify-content: space-between;
    border: 1px solid red;
    width: 100%;
}

.card_content_audio {

    border-radius: 12px;
    background: rgba(217, 217, 217, 0.23);
    display: flex;
    height: 40px;

    padding: var(--Size-Padding-paddingXS, 8px) 14px;
    align-items: center;
    gap: var(--Size-Padding-paddingXS, 8px);
}

.card_audio_details {
    display: flex;
    color: #000;
}

.card_audio_details_p {
    font-weight: 400;
    line-height: normal;
    font-size: 12px;
    display: flex;
    color: #000;

}

.card_audio_details .icon {
    margin-top: 3.5px;
    margin-right: 2px;
}

.card_audio_details .card_audio_details_p {
    margin-left: 5px;
}



.audio-slider {
    width: 200px !important;
    font-size: 20px;

}

.icons {
    padding: 10px;
    font-size: 20px;
    display: flex;
    justify-content: space-between;

}

.custom_slider {
    width: 41px;
    height: 4px;

}

.react-audio-player {
    margin-top: 40px !important;
}

.videoaudio_label {
    color: black;
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.videoaudio_label_two {
    color: black;
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
   
    border-right: 1px solid rgba(0, 0, 0, 0.06);
}

.videoaudio_label_third {
    color: black;
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.06); */
    /* border-right: 1px solid  rgba(0, 0, 0, 0.06); */
}
@media (max-width: 750px) {
    .videoaudio_label_third {
        line-height: 40px;
        
    
    }
    .gallery_heading{
        text-align: center;
      
    }
  
}

.video_gallery_section{

    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
}
.audio_gallery_section{

    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
}


.gallery_heading{
    background: #FAFAFA;
    color: #000000B2;
    width: 24.5%;
    color: black;
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    justify-content: center;
  
}
.text_video{

    padding-top: 30px;
}
.video_gallery_grid{
  display: flex;
  /* flex-direction: row; */

  flex-wrap: wrap;
  width: 100%;
   
    gap: 15px;
}
.video_gallery_item{

    flex: 1 1 150px; 
    max-width: 250px; 
    padding: 10px;
}
.audio_gallery_item{

    flex: 1 1 190px; 
    max-width: 250px; 
    margin-bottom: 8px;
}

.autio_div{
    width: 250px;
 
}
