.title {
 color: rgb(39, 101, 143) !important; 
 font-family :"poppins";
 font-weight: 500 !important;
 font-size: 18px;
}
.subheading {
    font-weight: 300 !important;
    line-height: 16.41px !important;
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.8) !important
}

.layout_employee {
    padding: 10px 12px ;
    background-color: rgb(240, 245, 255);
}
.layout_div {
    max-width: auto
}
.card_div {
    display: flex;
    justify-content : space-between;
    margin-top:12px ;
}

.card_text {
    display: block; 
    text-align: center;
    margin: 8px 0 ;
}

.card_second_div {
    max-height: 300px;
    overflow-y: auto;
    scrollbar-width: none; 
}
 .birthday_celebration_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    margin-bottom: 10px;
    border-radius: 12px;
    border: 1px solid #E2BF46;
    padding: 0 15px;
 }

 .birthday_celebration_div_second {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500,
 }

 .birthday_avatar {
    border-radius: 5px; 
    width: 40px;
    height: 40px
 }

 .birthday_img {
    margin-top: 34px;
    height: 30px
 }

 .anniversary_img {
     align-items: center;
     height: 30px
 }

 .anniversary_avatar { 
   border-radius: 5px; 
   width: 40px;
   height: 40px
 }

 .anniversary_div_second {
    display: flex;
    align-items:center 
 }
 .anniversary_div_first {
     text-align: center; 
     padding: 20px;
      color: "#888"
 }
 .imageText {
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:black;
    opacity:0.5;
    font-weight: bold;
    font-size: 12px;
    text-shadow: 0px 0px 5px rgba(0,0,0,0.8);
 }