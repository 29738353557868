.loan_and_insurance_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}

.loan_and_insurance_card {

  width: 30%;
  margin-bottom: 20px;
}

.loan_insurance_basic_card_content {
  gap: 8px;
  height: auto;
  margin-top: 13px;
  /* border: 1px solid red; */
  padding-top: 3px;
}

.laon_insurance_Basic_card {
  margin: 10px;
  flex: 1 1 20%;
  /* Flex-grow: 1, Flex-shrink: 1, Basis: 30% */
  margin-bottom: 20px;

  box-sizing: border-box;
  /* Ensure padding/margin is included in width */
  min-width: 220px;
  /* Set a minimum width to prevent cards from getting too small */
}

.loan_inusurance_label {
  color: #00000080;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 12px;
}

.loan_details_data {
  color: #000000b3;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-left: 12.5px;
  margin-right: 10px;
  margin-top: 5px;
  padding: 0;
}






.property_deatails_container {
  display: flex;
  justify-content: space-between;
  border: 1px solid #E4E7EC;
  /* margin: 0 15px 9px; */
  border-top: none;

}

.Basic_card {
  width: 30%;
  margin-bottom: 20px;
}

.loan_container {
  border: 1px solid #e4e7ec;
}

.loan_detail_heading {
  background: #E6F7FF;

  height: 46px;
  display: flex;
  justify-content: space-between;

}

.loan_description_data {
  margin: 12px 11px 12px 11px;
  color: #000000b2;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}

/* .insurance_btn_active {
    background-color: #1A75FB;;
    color: #FFFFFF ;
  }
  
  /* Optional: Adding some hover effect for better UX */
.insurance_btn:hover {
  background-color: #1a75fb;
  /* A slightly darker blue for hover effect */
  color: #ffffff;
}

.loan_insurance_card {
  /* border: 1px solid red; */
  width: 30%;
  margin-bottom: 20px;
}

.loan_details_heading {
  color: #000000b2;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.nominee_details {
  display: flex;
  flex-direction: row;
}

.basic_card-content {
  gap: 8px;
  height: auto;
  margin-top: 9px;
}

.basic_edit_container {
  display: flex;
  justify-content: flex-end;
  margin-right: 7px;
}

.loan_details_heading {
  border-top: 1px solid #e4e7ec;
  border-bottom: 1px solid #e4e7ec;
  background: #e6f7ff;
  border-right: none;
  font-weight: 600;
  padding: 10px;
}

.insuranceevaluation_table {
  border-radius: none !important;
}

.insuranceevaluation_table td {
  color: #585858 !important;
}

.insuranceevaluation_table thead th {
  color: #667085 !important;
  font-size: 14px;
  border-radius: 0px !important;
}

.insuranceevaluation_table {
  table-layout: fixed;
  width: 100%;
}

.insuranceevaluation_table .ant-table-cell {
  white-space: normal;
  /* Allow text to wrap inside table cells */
  word-wrap: break-word;
  /* Break long words to avoid overflow */
  overflow-wrap: break-word;
  /* Ensure word breaking works in all browsers */
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .insuranceevaluation_table .ant-table-cell {
    font-size: 14px;
    /* Adjust font size for smaller screens */
  }
}

@media (max-width: 992px) {
  .insuranceevaluation_table .ant-table-cell {
    font-size: 12px;
    /* Further reduce font size */
  }

  .insuranceevaluation_table {
    /* Optional: Adjust padding or margins for smaller screens */
    padding: 8px;
  }
}

@media (max-width: 768px) {
  .insuranceevaluation_table .ant-table-cell {
    /* display: block;  */
    width: 100%;
    box-sizing: border-box;
  }

  .insuranceevaluation_table {
    padding: 4px;
  }
}

@media (max-width: 576px) {
  .insuranceevaluation_table .ant-table-cell {
    font-size: 10px;
    /* Further reduce font size */
  }
}

/* .insurance_btn {
    border: 1px solid #1677FF;
    background-color: white;
    color: #1677FF;
} */

.tab_form_charges_main_data_table {
  width: 360px;
  padding: 12px;
  color: rgba(0, 0, 0, 0.7);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  background: #fff;
  border-bottom: 1px solid #e4e7ec;
}


.magma_insurance_container {
  border-top: 1px solid #E4E7EC;
  border-bottom: 1px solid #F1f1f1;
  background: #F1f1f1;
  border-right: none;
  font-weight: 600;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.magma-card {
  width: 30%;
}

.insured-customer-container {
  border-top: 1px solid #E4E7EC;
  font-weight: 600;
  padding: 10px;
  border: 1px solid #F1f1f1;
  flex-direction: row;
  margin-left: 14px;
  justify-content: space-between;
  margin-right: 14px;
}

.drawer-insurance-space {
  padding: 25px;
  /* width: 70vw; */
}

.icici-insurance-personal-detail {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.icici-insurance-personal-detail-other-question {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}


.icici-questions {
  width: 70%;
}

.icici-question-list {
  width: 100%;
}

.loan-view-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

.cco {
  width: 50vw;
}

.icici_view_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
}

.insurance_view_heading {
  color: rgb(39, 102, 144);
  font-weight: 700;
}

.insurance-download-button {
  background: rgb(109 161 195);
  border-radius: 10px;
  font-size: 14px;
  color: rgb(39, 102, 144);
  padding: 5px;
  padding-right: 5px;
}

.insurance-delete-button {
  /* background: #f0f0f0; */
  border: 1px solid #D3D3D3;
  font-size: 14px;
  padding: 5px;
  color: red;
  width: 33px;
  height: 32px;
  margin-top: 5px;
  text-align: center;
  cursor: pointer;
}

.insurance_button_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.insurance-edit-button {
  font-size: 14px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 5px;
  /* background: #f0f0f0; */
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.empty {
  display: block !important;
  overflow-x: hidden !important;
}

.loan_and_insurance_details_heading {
  background-color: #E6F7FF;
  border-bottom: 1px solid #E4E7EC;
  font-weight: 600;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}


.drawer-insurance-space {
  display: flex;
  flex-direction: column;
}

.insurance-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.insurance-items {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 67vw;
}

.insurance-question {
  flex: 1;
}

.female-insured-only {
  width: 100%;
}

.covid-details {
  width: 50vw;
}

.insurance-answer {
  flex: 1;
  text-align: right;
}

.insurance-remark {
  width: 100%;
  text-align: right;
  font-style: italic;
  margin-top: 5px;
}

.charges_table_container {
  overflow-x: auto;
}

.hdfc-image-gallery {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-left: 10px;
}

.hdfc-pdf-image {
  width: 100px;
  height: 100px;
  border: none;
  cursor: pointer;
}

.icici-image-gallery {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-left: 10px;
}

.icici-image {
  width: 100px;
  height: 100px;
  border: none;
  cursor: pointer;
}

.insurance-details-heading {
  margin-top: 5px;
  
}

.add-insurance-button {
  /* background:#f5930a !important; */
  background-color: #276690 !important;
  margin-right: 10px !important;
  color: white !important;
  border: 1px solid #276690 !important;
}

.magma-hdfc-icici {
  background-color: #FFFFFF;
  padding: 3px;
  border-radius: 6px;
  gap: 10px;
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

.magma-hdfc-icici-tabs {
  border: 1px solid #276690;
  margin-right: 10px;
  margin-top: 10px;
  width: 210px;
  background-color: #FFFFFF;
  /* Default background */
  color: #276690;
  /* Default text color */
  cursor: pointer;
  /* Add a pointer for better UX */
  transition: all 0.3s ease;
  /* Smooth transitions */
}

.magma-hdfc-icici-tabs.active {
  background-color: #276690 !important;
  color: #FFFFFF !important;
}

.heading_text_insurance_details {
  background-color: #e6f7ff;
  font-weight: 600;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #e4e7ec; */
}

.heading_text_insurance_details.no-border {
  border-bottom: none;
  /* Removed when collapsed */
}

.charges-underwriting-buttons {
  display: flex;
  gap: 10px;
  margin-right: 2px;


}

.add-charges-button {
  background-color: #276690 !important;
  /* margin-right: 10px !important; */
  color: white !important;
  border: 1px solid #276690 !important;

}


.charges{
  margin-top: 5px;
  margin-left: 5px;
}

.charges_heading{
  background-color: #e6f7ff;
  font-weight: 600;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}



/* ****************Loan Insurance Details In Loan Details *************************** */

.loan_insurance_main_container{
  margin: 10px 10px 10px 10px;
}

.loan_details_main_contenet{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.loan_details_contenet{
  width: 25%;
}
.loan_details_label {
  color: #00000080;
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    
}
.loan_inusurance_data {
  color: rgba(0, 0, 0, 0.7);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  padding: 5px 12px;
  margin-top: 0px;
  /* margin-left: 10px; */
  /* margin-right: 10px; */
}



@media (max-width: 1024px) {
  .loan_details_contenet {
    width: 32%; 
  }
}

@media (max-width: 768px) {
  .loan_details_contenet {
    width: 48%; 
  }
}

@media (max-width: 480px) {
  .loan_details_contenet {
    width: 100%;
  }
}
