.custom-button {
  background-color: #c3e4ec; /* Set the background color here */
  color: white;
}

.notification_div_heading {
  color: rgb(39, 101, 143);
  margin-top: 8px;
  margin-bottom: 12px;
}
