.send_back_query_label {
    color: #00000080;
    padding: 12px 0px 0px 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    flex: 1 
}

.send_back_value {
    /* margin-left: 12px; */
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    padding: 12px;
    flex: 1 
    /* margin-right: 20px; */
}